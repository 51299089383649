body {
  background-color: rgb(255, 255, 255);
  color: #2b2f33;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  margin-bottom: 100px;
}

.header {
  font-size: 7vw;
  font-family: 'Playfair Display', serif;
  line-height: 1;
  color: #3f4d5a;
}

.main-row {
  margin-top: 80px;
}

nav {
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.link:hover {
  cursor: pointer;
  color: rgb(228, 24, 51);
  text-decoration: none;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
}

.img-div {
  position: relative;
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  text-align: right;
}

.img-div:hover .overlay {
  opacity: 1;
}

.footer {
  background-color:#343a40;
  position: fixed;
  box-shadow: 2px 0 4px 2px rgba(0, 0, 0, 0.5);
  bottom: 0;
  margin-top: 50px;
}

.fab-div {
  text-align: right;
}

.fab {
  color: #7d868d;
  font-size: 3rem;
  padding: 15px;
}

.fab:hover {
  color: white;
  cursor: pointer;
}

.navbar-dark .navbar-nav .nav-link {
  font-size: 1.3rem;
  color: rgba(255,255,255,.5);
}

@media all and (max-width: 767px) {
  .header {
    font-size: 11vw;
  }
}

@media all and (max-width: 480px) { 
  
  .main-row {
    margin-top: 10vw;
  }
}