.info {
    font-size: 3vw;
    color: white;
    font-weight: 300;
    line-height: 1.2;
    padding-left: 20px;
}
  
.bold {
    font-weight: 500;
    padding-right: 10px;
    color: white;
}

.first-row {
    margin-top: 30px;
}
  
.info-name {
    padding: 20px;
    font-size: 5vw;
    color: white;
}

hr {
    border-color: rgba(255, 255, 255, 0.5);

}

.info-overlay {
    position: absolute;
    padding: 15px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .25s ease;
    background-color: rgb(91, 184, 207);
    color: black;
}

.site-div:hover .info-overlay {
    opacity: 1;
}

.site-div:active {
    opacity: 1;
}
.site-img {
    width: 100%;
    margin: 0;
    padding: 0;
}

.site-col {
    padding: 0;
}  
.right {
    color: white;
    font-weight: 500;
    text-align: right;
    position: absolute;
    bottom: 10px;
    right: 30px;
  }
  .ext-link {
    color: white;
    font-size: 1.5vw;
}
  .ext-link:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
}
  .info-col {
    margin-left: 0;
    padding-left: 0;
    background-color: #ece1e2;
}

@media all and (max-width: 767px) { 
    .info {
        font-size: 3vw;
        margin: 0;
        padding: 5px;
        line-height: 1;
    }

    .info-overlay {
        padding-left: 3px;
        padding-right: 0;
    }
    hr {
        margin: 0;
    }

    .info-name {
        font-size: 6vw;
        padding: 5px;
    }
}

@media all and (max-width: 604px) { 
    .info {
        font-size: 3.5vw;
    }

}