body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: rgb(255, 255, 255);
  color: #2b2f33;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  margin-bottom: 100px;
}

.header {
  font-size: 7vw;
  font-family: 'Playfair Display', serif;
  line-height: 1;
  color: #3f4d5a;
}

.main-row {
  margin-top: 80px;
}

nav {
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.link:hover {
  cursor: pointer;
  color: rgb(228, 24, 51);
  text-decoration: none;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.img-div {
  position: relative;
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  text-align: right;
}

.img-div:hover .overlay {
  opacity: 1;
}

.footer {
  background-color:#343a40;
  position: fixed;
  box-shadow: 2px 0 4px 2px rgba(0, 0, 0, 0.5);
  bottom: 0;
  margin-top: 50px;
}

.fab-div {
  text-align: right;
}

.fab {
  color: #7d868d;
  font-size: 3rem;
  padding: 15px;
}

.fab:hover {
  color: white;
  cursor: pointer;
}

.navbar-dark .navbar-nav .nav-link {
  font-size: 1.3rem;
  color: rgba(255,255,255,.5);
}

@media all and (max-width: 767px) {
  .header {
    font-size: 11vw;
  }
}

@media all and (max-width: 480px) { 
  
  .main-row {
    margin-top: 10vw;
  }
}
.about {
    padding: 5px;
    text-align: justify;
    font-size: 1.2rem;
}

.profile {
    width: 90%;
    text-align: right;
    margin-top: 30px;
}

.color-profile {
    width: 90%;
    text-align: right;
    margin-top: 30px;
}
  
.about-bold {
    font-weight: 500;
    color: #2b2f33;
}  

.tech {
    font-size: 1.2rem;
    padding: 5px;
}
  
.info {
    font-size: 3vw;
    color: white;
    font-weight: 300;
    line-height: 1.2;
    padding-left: 20px;
}
  
.bold {
    font-weight: 500;
    padding-right: 10px;
    color: white;
}

.first-row {
    margin-top: 30px;
}
  
.info-name {
    padding: 20px;
    font-size: 5vw;
    color: white;
}

hr {
    border-color: rgba(255, 255, 255, 0.5);

}

.info-overlay {
    position: absolute;
    padding: 15px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    -webkit-transition: .25s ease;
    transition: .25s ease;
    background-color: rgb(91, 184, 207);
    color: black;
}

.site-div:hover .info-overlay {
    opacity: 1;
}

.site-div:active {
    opacity: 1;
}
.site-img {
    width: 100%;
    margin: 0;
    padding: 0;
}

.site-col {
    padding: 0;
}  
.right {
    color: white;
    font-weight: 500;
    text-align: right;
    position: absolute;
    bottom: 10px;
    right: 30px;
  }
  .ext-link {
    color: white;
    font-size: 1.5vw;
}
  .ext-link:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
}
  .info-col {
    margin-left: 0;
    padding-left: 0;
    background-color: #ece1e2;
}

@media all and (max-width: 767px) { 
    .info {
        font-size: 3vw;
        margin: 0;
        padding: 5px;
        line-height: 1;
    }

    .info-overlay {
        padding-left: 3px;
        padding-right: 0;
    }
    hr {
        margin: 0;
    }

    .info-name {
        font-size: 6vw;
        padding: 5px;
    }
}

@media all and (max-width: 604px) { 
    .info {
        font-size: 3.5vw;
    }

}
.deck-row {
    background-color: rgb(246, 249, 250);
    border-radius: 2px;
    padding: 10px;
    margin-top: 210px;
    margin-bottom: 50px;
    border: 1px rgb(230, 234, 236) solid;
}


.animation-row {
    text-align: center;
}
  
.deck-img {
    width: 100%;
    max-width: 200px;
    border: 1px rgba(0, 30, 93, 0.15) solid;
    margin: 0;
    margin-right: 0;
    margin-left: 0;
    padding: 0;
    opacity: 0.6;
}

.deck-img:hover {
    opacity: 1;
    border: 1px rgb(65, 183, 212) solid;
    cursor: pointer;
}

.gif-col {
    padding-left: 5px;
    padding-right: 5px;
    padding: 5px;
    margin-top: 5px;
}

.stage {
    max-width: 450px;
}

@media all and (max-width: 767px) {
    .deck-row {
        margin-top: 20px;
    }
}
@media all and (max-width: 480px) { 
    .deck-img {
        width: 100px;
    }
}
